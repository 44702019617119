<template>
  <div class="login-wrap-un">
    <div class="sub-tit">欢迎登录</div>

    <div class="block">
      <div class="on-input">
        <div class="icon-wrap">
          <svg viewBox="0 0 1024 1024" width="20" height="20">
            <path
              d="M798 0H226c-35.3 0-64 28.7-64 64v896c0 35.3 28.7 64 64 64h572c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64z m0 928c0 17.6-14.4 32-32 32H258c-17.6 0-32-14.4-32-32V773.8h572V928zM226 709.8V96c0-17.6 14.4-32 32-32h508c17.6 0 32 14.4 32 32v613.8H226z"
            ></path>
            <path d="M457 869.4a55 55 0 1 0 110 0 55 55 0 1 0-110 0z"></path>
          </svg>
          手机号
        </div>
        <div class="input-wrap">
          <van-field
            v-model="form.mobile"
            placeholder="请输入手机号"
            maxlength="11"
          />
        </div>
      </div>
      <div class="on-input">
        <div class="icon-wrap">
          <svg viewBox="0 0 1024 1024" width="20" height="20">
            <path
              d="M877.19 151.588L927 144.42v481.433l-2.542 7.15a622.398 622.398 0 0 1-142.386 227.621l-0.182 0.185-0.183 0.183c-36.781 36.581-80.07 70.963-122.936 96.574C607.109 988.43 557.585 1006 512.5 1006c-45.088 0-94.592-17.534-146.202-48.35-42.884-25.605-86.18-60.003-123.018-96.671l-0.191-0.19-0.189-0.193A624.791 624.791 0 0 1 100.555 633.04L98 625.874V150.83l44.375 0.378c48.96 0.418 97.62-7.685 143.797-23.945l0.521-0.184 0.526-0.17a748.46 748.46 0 0 0 199.96-99.893L511.573 9.85l24.979 16.306a986.95 986.95 0 0 0 201.955 100.987c36.862 11.904 69.104 19.111 96.726 22.556 18.276 2.28 31.852 2.633 40.737 1.992 0.527-0.038 0.934-0.074 1.22-0.103z m-52.849 85.435c-33.369-4.162-71.286-12.66-113.692-26.402l-0.73-0.237-0.72-0.261a1074.982 1074.982 0 0 1-195.89-94.53 836.489 836.489 0 0 1-198.436 94.86A510.113 510.113 0 0 1 186 237.65V610.5a536.8 536.8 0 0 0 119.554 188.3C374.804 867.672 459.142 918 512.5 918c53.292 0 137.733-50.42 206.967-119.22A534.407 534.407 0 0 0 839 610.522v-371.92c-4.676-0.42-9.563-0.944-14.659-1.58z m-354.355 342.84l197.99-197.99c17.183-17.182 45.043-17.182 62.226 0 17.183 17.184 17.183 45.043 0 62.226L469.986 704.314 310.887 545.215c-17.183-17.183-17.183-45.042 0-62.225s45.043-17.183 62.226 0l96.873 96.874z"
              fill="#000000"
            ></path>
          </svg>
          验证码
        </div>
        <div class="input-wrap verify-code">
          <van-field v-model="form.smsCode" placeholder="请输入验证码" />
          <div :class="['send-btn', sending ? 'sending' : '']" @click="getCode">
            {{ sending ? `${second}s` : "发送验证码" }}
          </div>
        </div>
      </div>
      <div class="agreement">
        <van-checkbox v-model="agreement" checked-color="#1C97FF">
          我已阅读并同意
          <span class="link" @click.stop="toAgreement">
            《用户协议及隐私政策》
          </span>
        </van-checkbox>
      </div>

      <div class="btn">立即登录</div>
    </div>
  </div>
</template>
<script>
// 暂未使用
import { smsLgcode } from "@/api/login";
export default {
  data() {
    return {
      agreement: false,
      form: {},
      loading: false,
      sending: false,
      second: 60
    };
  },
  methods: {
    async getCode() {
      if (this.sending) return;
      const mobilePass = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.form.mobile);
      if (!mobilePass) {
        this.$toast.fail("请输入正确的手机号");
        return;
      }
      try {
        await smsLgcode(this.form.mobile);
        this.$toast.success("验证码已发送，请注意查收！");
        this.sending = true;
        this.itv = setInterval(() => {
          this.second--;
          if (this.second <= 0) {
            this.second = 60;
            this.sending = false;
            clearInterval(this.itv);
          }
        }, 1000);
        return Promise.resolve(true);
      } catch (error) {
        this.sending = false;
        this.$errMsg(error);
        return Promise.reject(error);
      }
    },
    toAgreement() {
      window.open("https://h5.songchewang.com/#/agreement/register");
    }
  }
};
</script>
<style lang="scss" scoped>
.login-wrap-un {
  background-color: #ebf6ff;
  background-image: url("/images/login/bg@3x.png");
  background-repeat: no-repeat;
  background-size: 750px 472px;
  background-position: 0 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
  .sub-tit {
    font-weight: bold;
    font-size: 40px;
    margin-top: 102px;
    padding: 0 32px;
  }
  .block {
    margin-top: 150px;
    padding: 48px;
    .on-input {
      .icon-wrap {
        display: flex;
        align-items: center;
        height: 80px;
        font-size: 14px;
        svg {
          margin-right: 12px;
        }
      }
      .input-wrap {
        margin-bottom: 32px;
        background: #f6fbff;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
        border-radius: 48px;
        width: 590px;
        height: 96px;
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 0 32px;
        img {
          width: 48px;
          height: 48px;
          margin-right: 24px;
        }
        .van-cell {
          padding: 0;
          background: #f6fbff;
          flex: 1;
          &::after {
            content: unset;
          }
        }
        &.verify-code {
          display: flex;
          justify-content: space-between;
          .send-btn {
            width: 150px;
            height: 28px;
            line-height: 28px;
            border-left: 2px solid #1890ff;
            font-size: 28px;
            font-weight: 400;
            color: #1890ff;
            text-align: center;
            padding-left: 6px;
            &.sending {
              color: #999;
            }
          }
        }
      }
    }
    .agreement {
      margin-top: 10px;
      display: flex;
      align-items: center;

      font-size: 28px;
      .link {
        color: #1890ff;
      }
    }
    .btn {
      width: 590px;
      height: 96px;
      background: linear-gradient(180deg, #35c3ff 0%, #1890ff 100%);
      border-radius: 48px;
      font-size: 32px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      margin-top: 32px;
      line-height: 32px;
    }
  }
}
</style>
